function LoadingTableItem() {
    const fakeItemsCount = 10;
    return (
        [...Array(fakeItemsCount)].map((_, idx) => (
            <tr
            className={
                idx % 2 === 0
                    ? "bg-slate-100 hover:bg-slate-200"
                    : "hover:bg-slate-200"
            }
            key={idx}
        >
            <td className="h-24 w-24 sm:h-48 sm:w-48 p-4 border-b border-blue-gray-50">
                <div className="flex justify-center items-center gap-3">
                    <div className="animate-pulse rounded bg-gray-800/10 h-24 w-24 sm:h-48 sm:w-48"></div>
                </div>
            </td>
            <td className="max-w-32 s:max-w-46 p-2 border-b border-blue-gray-50">
                <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                        <p className="block font-sans text-sm antialiased font-normal leading-normal text-blue-gray-900">
                            <p class="animate-pulse w-32 s:w-46 h-4 bg-gray-800/10 rounded">{""}</p>
                        </p>
                    </div>
                </div>
            </td>
            <td className="p-4 border-b border-blue-gray-50">
                <div className="flex flex-col">
                    <p className="w-16 h-4 animate-pulse bg-gray-800/10 rounded"></p>
                </div>
            </td>
        </tr>
        ))
    );
}

export default LoadingTableItem;